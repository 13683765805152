<template>
  <article
    v-if="!loading && survey"
    class="box survey mt-4"
  >
    <h1 class="page-title">
      {{ `${$t('surveys.one')} «${localDocumentTitle}»` }}
    </h1>

    <SurveyCard
      :survey="survey"
    />

    <div
      v-if="survey.description"
      class="mb-2"
    >
      <VExpansionPanels>
        <VExpansionPanel>
          <VExpansionPanelHeader>
            {{ $t('descriptions.one') }} {{ $t('surveys.genitive_one').toLowerCase() }}
          </VExpansionPanelHeader>
          <VExpansionPanelContent>
            <div v-html="survey.description" />
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>

    <VCard v-if="survey.questions_count">
      <VCardTitle>
        {{ $t('questions.other') }}:
      </VCardTitle>

      <VCardText>
        <VCard
          v-for="(question, idx) in survey.questions"
          :key="question.question_id"
          outlined
          class="task"
        >
          <SurveyQuestion
            :key="question.question_id"
            v-model="userAnswers[question.id]"
            :survey="survey"
            :question="question"
            :index="idx"
            :user-answers="question.user_answers || []"
          />
        </VCard>
      </VCardText>

      <VCardActions
        v-if="!survey.is_completed && survey.questions_count"
        class="actions"
      >
        <VBtn
          :large="$vuetify.breakpoint.lgAndUp"
          color="primary"
          @click.prevent="save"
        >
          <VIcon left>
            done
          </VIcon> {{ $t('complete.one') }}
        </VBtn>
      </VCardActions>
    </VCard>

    <VCard v-else>
      <VAlert>
        Опрос не&nbsp;содержит вопросов,
        <RouterLink :to="{ name: 'messenger' }">
          обратитесь пожалуйста к&nbsp;администратору
        </RouterLink>.
      </VAlert>
    </VCard>
  </article>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapActions } from 'vuex'

import documentTitle from '@/mixins/documentTitle'
import { getSurvey, postSurvey } from '@/api/api'
import * as actions from '@/store/actions/types'

import SurveyCard from '@components/SurveyCard.vue'
import SurveyQuestion from '@components/SurveyQuestion.vue'
import { AnswerSettings } from '@/utils/quiz'
export default {
  name: 'TheSurvey',

  components: {
    SurveyCard,
    SurveyQuestion
  },

  mixins: [
    documentTitle
  ],

  data () {
    return {
      loading: true,
      survey: null,
      questions: new Map(),
      userAnswers: {}
    }
  },

  computed: {},

  watch: {
    $route: {
      handler: async function (to) {
        this.loading = true

        try {
          const { now, survey } = await getSurvey(to.params.surveyID)
          this.updateNow(now)
          this.survey = survey || {}
          this.preparedQuestions(survey.questions || [])
          if (!survey || (!survey.is_active && survey.status.code !== 'completed')) {
            throw new Error(`Опрос ${survey.id} в данный момент недоступен`)
          }

          this.documentTitle = survey.title
          this.loading = false
        } catch (err) {
          this.createNotification({
            type: 'error',
            msg: err.message
          })

          if (!err.message.includes('в своём профиле')) {
            this.$router.replace({ name: 'surveys' }).catch(() => {})
          }
        }
      },
      immediate: true
    }
  },

  created () {},

  methods: {
    ...mapActions({
      createNotification: actions.CREATE_NOTIFICATION,
      updateNow: actions.UPDATE_NOW
    }),

    type () {
      return this.question.type?.code || AnswerSettings.DEFAULT_QUESTION_TYPE
    },

    isRankingType (type) {
      return type === AnswerSettings.QUESTION_TYPE_RANKING
    },

    isGradingType (type) {
      return type === AnswerSettings.QUESTION_TYPE_GRADING
    },

    isTextType (type) {
      return type === AnswerSettings.QUESTION_TYPE_TEXT
    },

    preparedQuestions (questions) {
      return questions.map(question => {
        switch (question?.type?.code) {
          case 'ranking_answers':
            question.type.code = 'ranking'
            break
          case 'text_answer':
            question.type.code = 'text'
            break
          case 'single_answer':
            question.type.code = 'simple'
            break
          case 'multiple_answers':
            question.multiple_choice = true
            question.type.code = 'simple'
            break
        }

        const type = question.type?.code

        if (this.isRankingType(type) && this.userAnswers.length) {
          this.question.answers = this.userAnswers || this.question.answers
        }

        question.answers = this.preparedAnswers(question, question.answers)

        if ((this.isTextType(type) || this.isRankingType(type)) && question.user_answers?.length) {
          question.user_answers = this.preparedAnswers(question, question.user_answers)
          question.answers = question.user_answers || question.answers
        } else if (this.isGradingType(type)) {
          question.user_answers = this.preparedAnswers(question, [question.user_grade])
        } else {
          question.user_answers = question.answers
            .filter(answer => (question.user_answers || []).includes(answer.answer))
        }

        this.questions.set(question.id, question)
        return question
      })
    },

    preparedAnswers (question, answers) {
      return (answers || []).map((_answer, idx) => {
        return {
          answer: _answer,
          answer_id: idx,
          settings: {
            question_type: question.type.code,
            question_id: question.question_id
          }
        }
      })
    },

    preparedUserAnswers (question, answers) {
      return question.answers.filter(answer => (answers || []).includes(answer.answer))
    },

    async save () {
      const data = new FormData()
      data.append('_replace', 1)

      for (const id of Object.keys(this.userAnswers)) {
        const question = this.questions.get(Number(id))
        if (this.isGradingType(question?.type?.code)) {
          this.userAnswers[id].forEach(answer => {
            data.append(`user_grade[${id}]`, answer)
          })
        } else {
          this.userAnswers[id].forEach(answer => {
            data.append(`user_answer[${id}]`, answer)
          })
        }
      }

      const { survey } = await postSurvey(this.survey.id, data)
      this.survey = survey || {}
      this.preparedQuestions(this.survey.questions || [])
    },
  }
}
</script>
