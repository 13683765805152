<template>
  <article class="user-edit box">
    <VForm
      ref="form"
      v-model="valid"
      class="fill-height"
      lazy-validation
      @submit.prevent="save"
    >
      <VCard class="user-edit__body">
        <VAvatar
          class="user-edit__avatar"
          :size="$vuetify.breakpoint.smAndDown ? 80 : 256"
          rounded
        >
          <VImg
            v-if="profile.photo"
            :src="`/${profile.photo}`"
            :alt="account.title"
          />
          <VIcon v-else>
            person
          </VIcon>
        </VAvatar>
        <div class="actions">
          <VBtn
            tag="label"
            class="mb-2"
            :title="$t('$app.upload_photo')"
            block
            depressed
            :large="$vuetify.breakpoint.lgAndUp"
          >
            <VIcon
              left
              size="24"
            >
              attach_file
            </VIcon>
            <span>{{ $t('$app.upload_photo') }}</span>
            <input
              name="photo"
              type="file"
              accept="image/*"
              hidden
              @change="uploadPhoto"
            >
          </VBtn>
          <VBtn
            class="mb-2"
            text
            color="error"
            block
            :large="$vuetify.breakpoint.lgAndUp"
            @click="deletePhoto"
          >
            {{ $t('$app.delete_photo') }}
          </VBtn>
          <VDivider />
          <VList class="mt-2">
            <VListItemGroup
              v-model="currentItem"
              :mandatory="$vuetify.breakpoint.mdAndUp"
            >
              <VListItem value="account">
                <VListItemIcon>
                  <VIcon>manage_accounts</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{{ $t('accounts.one') }}</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="base-information">
                <VListItemIcon>
                  <VIcon>info</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>Основная информация</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="contacts-information">
                <VListItemIcon>
                  <VIcon>contact_mail</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>Контактная информация</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="education">
                <VListItemIcon>
                  <VIcon>school</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>Образование</VListItemTitle>
                </VListItemContent>
              </VListItem>
              <VListItem value="work">
                <VListItemIcon>
                  <VIcon>work</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>Работа</VListItemTitle>
                </VListItemContent>
              </VListItem>
            </VListItemGroup>
          </VList>
        </div>

        <h1 class="user-edit__title">
          {{ account.title }}
        </h1>

        <div class="user-edit__content">
          <Component
            :is="component"
            v-bind="{...props}"
          >
            <!-- Аккаунт -->
            <VCard
              v-show="currentItem === 'account'"
              flat
            >
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>{{ $t('accounts.one') }}</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.username"
                      outlined
                      :label="$t('usernames.one')"
                      :rules="[
                        v => !!(v || '').trim().length || 'Это поле обязательно',
                        v => (v || '').trim().length <= 254 || 'Логин должен быть не больше 254 символов'
                      ]"
                      @input="trimField($event, 'username')"
                    />
                  </VCol>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.password"
                      outlined
                      :label="$t('passwords.one')"
                      :rules="[
                        v => !!(v || '').trim().length || 'Это поле обязательно',
                        v => (v || '').trim().length >= 6 || 'Пароль должен быть не меньше 6 символов',
                        v => (v || '').trim().length <= 254 || 'Пароль должен быть не больше 254 символов'
                      ]"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :append-icon="isPasswordVisible ? 'visibility_off' : 'visibility'"
                      @input="trimField($event, 'password')"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    />
                  </VCol>
                  <VCol cols="12">
                    <VSwitch
                      v-model="profile.generatePassword"
                      :label="$t('generate_new_password.one')"
                    />
                    <VSwitch
                      v-if="account.email && profile.generatePassword"
                      v-model="profile.sendCredentials"
                      label="Выслать новый логин/пароль"
                    />
                  </VCol>
                  <VCol cols="12">
                    <VAutocomplete
                      v-model="profile.timezone"
                      outlined
                      :items="timezoneItems"
                      label="Часовой пояс"
                    />
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>

            <!-- Основная информация -->
            <VCard
              v-show="currentItem === 'base-information'"
              flat
            >
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>Основная информация</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.title"
                      outlined
                      :label="$t('full_name_person.one')"
                      :rules="[
                        v => !!(v || '').trim().length || 'Это поле обязательно',
                        v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов'
                      ]"
                      class="required"
                      @input="trimField($event, 'title')"
                    />
                    <VExpansionPanels>
                      <VExpansionPanel>
                        <VExpansionPanelHeader>
                          ФИО: падежные формы
                        </VExpansionPanelHeader>
                        <VExpansionPanelContent>
                          <VTextField
                            v-model="profile.titleRomanized"
                            outlined
                            label="ФИО латиницей"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleRomanized')"
                          />
                          <VTextField
                            v-model="profile.titleGenitive"
                            outlined
                            label="ФИО в родительном падеже"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleGenitive')"
                          />
                          <VTextField
                            v-model="profile.titleDative"
                            outlined
                            label="ФИО в дательном падеже"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleDative')"
                          />
                          <VTextField
                            v-model="profile.titleAccusative"
                            outlined
                            label="ФИО в винительном падеже"
                            :rules="[ v => (v || '').trim().length <= 1023 || 'ФИО должно быть не больше 1023 символов' ]"
                            @input="trimField($event, 'titleAccusative')"
                          />
                        </VExpansionPanelContent>
                      </VExpansionPanel>
                    </VExpansionPanels>
                  </VCol>
                  <VCol cols="12">
                    <VRadioGroup
                      v-model="profile.gender"
                      :label="$t('gender.one')"
                      row
                    >
                      <VRadio
                        label="Женский"
                        value="f"
                      />
                      <VRadio
                        label="Мужской"
                        value="m"
                      />
                    </VRadioGroup>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.dob"
                      outlined
                      :label="$t('dob.one')"
                      :rules="[ v => (v || '').trim().length <= 63 || 'В дате рождения не должно быть больше 63 символов' ]"
                      @input="trimField($event, 'dob')"
                    />
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VAutocomplete
                      v-model="profile.citizenship"
                      outlined
                      :items="citizenshipItems"
                      item-text="title"
                      item-value="code"
                      :label="$t('citizenship.one')"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.inn"
                      outlined
                      :label="$t('inn.one')"
                      placeholder="AA BB CCCCC D или AA BB CCCCCC DD"
                      :rules="[
                        v => preparedRule(requiredFields.inn, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 12 || 'В ИНН должно быть не больше 12 символов',
                        v => /^([0-9]{10}([0-9]{2})?)?$/.test(v)
                      ]"
                      :class="requiredFields.inn && 'required'"
                      @input="trimField($event, 'inn')"
                    />
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      ref="snils"
                      v-model="profile.snils"
                      v-mask="'###-###-### ##'"
                      outlined
                      :label="$t('snils.one')"
                      placeholder="XXX-XXX-XXX YY"
                      :rules="[ v => preparedRule(requiredFields.snils, v) || validateSNILS(v) ]"
                      :class="requiredFields.snils && 'required'"
                      @input="trimField($event, 'snils')"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.phone"
                      outlined
                      :label="$t('phones.one')"
                      type="tel"
                      :rules="[
                        v => preparedRule(requiredFields.phone, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов'
                      ]"
                      :class="requiredFields.phone && 'required'"
                      @input="trimField($event, 'phone')"
                    />
                  </VCol>
                  <VCol
                    cols="12"
                    md="6"
                  >
                    <VTextField
                      v-model="profile.email"
                      outlined
                      :label="$t('emails.one')"
                      type="email"
                      :rules="[
                        v => preparedRule(requiredFields.email, v) || (v || '').trim().length > 0 || 'Заполните поле',
                        v => !(v || '').length || /.+@.+/.test(v) || 'Невалидный email',
                        v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов',
                      ]"
                      :class="requiredFields.email && 'required'"
                      @input="trimField($event, 'email')"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="12">
                    <VTextarea
                      v-model="profile.passport"
                      outlined
                      :label="$t('user_profile_passport_info.one')"
                      :placeholder="$t('user_profile_passport_info_placeholder.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов' ]"
                      @input="trimField($event, 'passport')"
                    />
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>

            <!-- Контактная информация -->
            <VCard
              v-show="currentItem === 'contacts-information'"
              flat
            >
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>Контактная информация</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextarea
                      v-model="profile.address"
                      outlined
                      :label="$t('address_of_a_person.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов' ]"
                      @input="trimField($event, 'address')"
                    />
                    <VTextarea
                      v-model="profile.physicalAddress"
                      outlined
                      :label="$t('physical_address_of_a_person.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов' ]"
                      persistent-hint
                      hint="Оставьте поле пустым, если не отличается от адреса регистрации."
                      @input="trimField($event, 'physicalAddress')"
                    />
                    <VTextarea
                      v-model="profile.postalAddress"
                      outlined
                      :label="$t('postal_address_of_a_person.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов' ]"
                      persistent-hint
                      hint="Оставьте поле пустым, если не отличается от адреса проживания."
                      @input="trimField($event, 'postalAddress')"
                    />
                  </VCol>
                </VRow>
              </VCardText>

              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>

            <!-- Образование -->
            <VCard
              v-show="currentItem === 'education'"
              flat
            >
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>Образование</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextarea
                      v-model="profile.education"
                      outlined
                      :label="$t('information_education.one')"
                      rows="3"
                      :rules="[ v => (v || '').trim().length <= 1023 || 'Это поле должно быть не больше 1023 символов' ]"
                      @input="trimField($event, 'education')"
                    />
                  </VCol>
                </VRow>
                <VDivider class="mb-4" />
                <VCard color="#f5f5f5">
                  <VCardTitle>{{ $t('information_doc_education_of_the_user.one') }}:</VCardTitle>
                  <VCardText>
                    <VCard
                      v-for="(certificate, idx) in profile.certificates"
                      :key="idx"
                      :class="{ 'mb-4': idx !== profile.certificates.length }"
                    >
                      <VListItem>
                        <VListItemContent />
                        <VListItemAction>
                          <VBtn
                            icon
                            color="error"
                            :title="$t('delete.one')"
                            @click="deleteCertificate(idx)"
                          >
                            <VIcon>close</VIcon>
                          </VBtn>
                        </VListItemAction>
                      </VListItem>
                      <VDivider />
                      <VCardText>
                        <VRow>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VSelect
                              v-model="certificate.level"
                              dense
                              hide-details
                              :items="certificateLevels"
                              clearable
                              :label="$t('education_level.one')"
                              outlined
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VTextField
                              v-model="certificate.fullname"
                              dense
                              hide-details
                              outlined
                              :label="$t('certificate_full_name.one')"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="4"
                          >
                            <VTextField
                              v-model="certificate.series"
                              dense
                              hide-details
                              outlined
                              :label="$t('series.one')"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="4"
                          >
                            <VTextField
                              v-model="certificate.number"
                              dense
                              hide-details
                              outlined
                              :label="$t('numbers.one')"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="4"
                          >
                            <VTextField
                              v-model="certificate.signed_at"
                              dense
                              hide-details
                              outlined
                              :label="$t('signed_at.one')"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VTextField
                              v-model="certificate.edu"
                              dense
                              hide-details
                              outlined
                              :label="$t('educational_institution.one')"
                            />
                          </VCol>
                          <VCol
                            cols="12"
                            md="6"
                          >
                            <VTextField
                              v-model="certificate.edu_level"
                              dense
                              hide-details
                              outlined
                              :label=" $t('educational_institution_level.one')"
                            />
                          </VCol>
                        </VRow>
                      </VCardText>
                    </VCard>
                  </VCardText>
                  <VCardActions>
                    <VBtn
                      color="#fff"
                      depressed
                      @click="addCertificate"
                    >
                      Добавить документ
                    </VBtn>
                  </VCardActions>
                </VCard>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>

            <!-- Работа -->
            <VCard
              v-show="currentItem === 'work'"
              flat
            >
              <VToolbar
                v-if="component !== 'div'"
                class="mb-6"
              >
                <VBtn
                  icon
                  :title="$t('close.one')"
                  @click="currentItem = ''"
                >
                  <VIcon>close</VIcon>
                </VBtn>
                <VToolbarTitle>Работа</VToolbarTitle>
              </VToolbar>
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="profile.employer"
                      outlined
                      :label="$t('employers.one')"
                      :rules="[ v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов' ]"
                      @input="trimField($event, 'employer')"
                    />
                    <VTextField
                      v-model="profile.position"
                      outlined
                      :label="$t('user_profile_position.one')"
                      :rules="[ v => (v || '').trim().length <= 254 || 'Это поле должно быть не больше 254 символов' ]"
                      @input="trimField($event, 'position')"
                    />
                  </VCol>
                </VRow>
              </VCardText>
              <VCardActions>
                <VBtn
                  depressed
                  :to="`/users/${account.id}`"
                  :large="$vuetify.breakpoint.lgAndUp"
                >
                  {{ $t('cancel.one') }}
                </VBtn>
                <VBtn
                  depressed
                  type="submit"
                  :disabled="!hasChanged"
                  :loading="isSubmitting"
                  :large="$vuetify.breakpoint.lgAndUp"
                  color="primary"
                  @click="save"
                >
                  <VIcon left>
                    save
                  </VIcon> {{ $t('save.one') }}
                </VBtn>
              </VCardActions>
            </VCard>
          </Component>
        </div>
      </VCard>
    </VForm>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import documentTitle from '@/mixins/documentTitle'
import { updateUser, getUserSettings, getUser } from '@/api/api'
import store from '@/store/store'
import { COUNTRIES } from '@/utils/countries'
import { TIMEZONES } from '@/utils/timezones'
import { validateSNILS } from '../utils/snilsValidator'
import { VDialog } from 'vuetify/lib'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import { mask } from '@titou10/v-mask'

class Profile {
  constructor (account = {}, settings = {}) {
    this.title = account.title || ''
    this.titleRomanized = account.title_romanized || ''
    this.titleDative = account.title_dative || ''
    this.titleAccusative = account.title_accusative || ''
    this.titleGenitive = account.title_genitive || ''
    this.username = account.username || ''
    this.password = account.password || ''
    this.position = account.position || ''
    this.email = account.profile?.email || ''
    this.phone = account.profile?.phone || ''
    this.photo = account.profile?.photo || ''
    this.employer = account.profile?.employer || ''
    this.inn = account.profile?.inn || ''
    this.snils = account.profile?.snils || ''
    this.gender = account.profile?.gender || ''
    this.dob = account.profile?.dob || ''
    this.citizenship = account.profile?.citizenship || ''
    this.passport = account.profile?.passport || ''
    this.locality = account.profile?.locality || ''
    this.address = account.profile?.address || ''
    this.physicalAddress = account.profile?.physical_address || ''
    this.postalAddress = account.profile?.postal_address || ''
    this.education = account.profile?.education || ''
    this.certificates = account.profile?.certificates || []
    this.timezone = settings.timezone || ''
    this.generatePassword = 0
    this.sendCredentials = false
  }
}

function makeObservable (target, handler) {
  return new Proxy(target, {
    set () {
      const success = Reflect.set(...arguments)
      if (success && handler) {
        handler()
      }

      return success
    }
  })
}

export default {
  name: 'TheUserEdit',
  components: { VDialog },
  directives: { mask },
  mixins: [documentTitle],

  beforeRouteEnter (to, from, next) {
    const { id, profile } = store.getters[getters.ACCOUNT]
    if (!profile.can_change_himself) {
      next({ name: 'user', params: { userID: id } })
    }

    next()
  },

  data () {
    return {
      valid: false,
      currentItem: '',
      isSubmitting: false,
      isDeletePhoto: 0,
      isPasswordVisible: false,
      hasChanged: false,
      timezoneItems: TIMEZONES,
      citizenshipItems: COUNTRIES,
      certificateLevels: [
        { text: 'Высшее образование', value: 'Высшее образование' },
        { text: 'Среднее профессиональное образование', value: 'Среднее профессиональное образование' },
        { text: 'Справка', value: 'Справка' },
        { text: 'Стаж', value: 'Стаж' },
        { text: 'Пункт 1 правил формирования ФИС ФРДО', value: 'Пункт 1 правил формирования ФИС ФРДО' }
      ],
      profile: {},
      validateSNILS
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      vendor: getters.VENDOR
    }),

    component () {
      return this.$vuetify.breakpoint.smAndDown ? 'v-dialog' : 'div'
    },

    requiredFields () {
      const fields = {}
      this.vendor?.branding?.required_listener_profile_fields.forEach(field => {
        fields[field] = true
      })

      return fields
    },

    props () {
      if (this.component === 'v-dialog') {
        return {
          transition: 'slide-x-transition',
          fullscreen: true,
          value: this.currentItem
        }
      }

      return {}
    }
  },

  watch: {
    currentItem: {
      handler: 'updateItem'
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      setAccount: actions.SET_ACCOUNT,
      createNotification: actions.CREATE_NOTIFICATION
    }),

    uploadPhoto (evt) {
      this.profile.photo = evt.target.files[0]
      this.save()
    },

    deletePhoto () {
      this.isDeletePhoto = 1
      this.save()
    },

    deleteCertificate (idx) {
      this.profile.certificates.splice(idx, 1)
      this.profile.certificates = [...this.profile.certificates]
    },

    updateItem (newVal) {
      const query = Object.assign({}, this.$route.query, {
        settings: newVal
      })

      this.$router.replace({ query }).catch(() => {})
    },

    addCertificate () {
      this.profile.certificates = [...this.profile.certificates, {
        level: '',
        fullname: '',
        series: '',
        number: '',
        edu: '',
        edu_level: '',
        signed_at: '',
        key: this.profile.certificates.length
      }]
    },

    trimField (v, field) {
      this[field] = (v || '').trim()
    },

    collectData () {
      const profile = this.profile
      const formData = new FormData()
      formData.append('photo', profile.photo)
      formData.append('delete_photo', this.isDeletePhoto)
      formData.append('username', profile.username)
      formData.append('password', profile.password === this.account.password ? '' : profile.password)
      formData.append('generate_password', Number(profile.generatePassword))
      formData.append('send_credentials', profile.sendCredentials)
      formData.append('timezone', profile.timezone)
      formData.append('title', profile.title)
      formData.append('title_romanized', profile.titleRomanized)
      formData.append('title_genitive', profile.titleGenitive)
      formData.append('title_dative', profile.titleDative)
      formData.append('title_accusative', profile.titleAccusative)
      formData.append('profile[person][gender]', profile.gender)
      formData.append('profile[person][dob]', profile.dob)
      formData.append('profile[person][citizenship]', profile.citizenship)
      formData.append('profile[person][inn]', profile.inn)
      formData.append('profile[person][snils]', profile.snils)
      formData.append('profile[person][passport]', profile.passport)
      formData.append('profile[person][phone]', profile.phone)
      formData.append('profile[person][email]', profile.email)
      formData.append('profile[person][locality]', profile.locality)
      formData.append('profile[person][address]', profile.address)
      formData.append('profile[person][physical_address]', profile.physicalAddress)
      formData.append('profile[person][postal_address]', profile.postalAddress)
      formData.append('profile[person][education]', profile.education)
      formData.append('profile[person][employer]', profile.employer)
      formData.append('position', profile.position)

      profile.certificates.forEach((certificate, idx) => {
        formData.append('profile[certificates][key]', idx)
        formData.append('profile[certificates][level]', certificate.level)
        formData.append('profile[certificates][fullname]', certificate.fullname)
        formData.append('profile[certificates][series]', certificate.series)
        formData.append('profile[certificates][number]', certificate.number)
        formData.append('profile[certificates][signed_at]', certificate.signed_at)
        formData.append('profile[certificates][edu]', certificate.edu)
        formData.append('profile[certificates][edu_level]', certificate.edu_level)
      })

      return formData
    },

    handleСhanges () {
      this.hasChanged = true
    },

    async save () {
      const form = this.$refs.form
      if (!form || !form.validate()) {
        return
      }
      this.isSubmitting = true

      const { user } = await updateUser(this.account.id, this.collectData())
      this.setAccount(user)

      const { settings } = await getUserSettings(this.account.id)
      this.settings = settings
      this.profile = makeObservable(new Profile(this.account, this.settings), this.handleСhanges)

      this.createNotification({
        msg: 'Изменения сохранены!',
        type: 'success'
      })

      this.hasChanged = false
      this.isSubmitting = false
    },

    async register () {
      const query = this.$route.query
      if (query.settings) {
        this.currentItem = query.settings
      }

      const { user } = await getUser(this.account.id)
      if (!user) {
        return
      }

      this.setAccount(user)
      this.documentTitle = this.account.title

      const { settings } = await getUserSettings(this.account.id)
      this.settings = settings
      this.profile = makeObservable(new Profile(this.account, this.settings), this.handleСhanges)
    },

    preparedRule (required, value) {
      const length = (value || '').trim().length
      return !required && !length
    },
  }
}
</script>

<style lang="scss">
.user-edit {
  height: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 1rem;
  }
}

.user-edit__avatar {
  grid-area: avatar;
  background-color: #ccc;

  .v-icon {
    font-size: 40px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 160px;
    }
  }
}

.user-edit__title {
  grid-area: title;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 0.75rem;
  margin-left: 0.75rem;
}

.user-edit__content {
  grid-area: content;
  max-width: 60rem;
}

.user-edit__body {
  height: 100%;
  padding: 1rem;
  display: grid;
  gap: 0.75rem;
  grid-template-areas:
    "avatar title"
    "actions actions"
    "content content";
  grid-template-columns: 80px 1fr;
  grid-auto-rows: min-content;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 256px 1fr;
    grid-template-areas:
      "avatar title"
      "avatar content"
      "actions content"
      ". content";
  }

  > .actions {
    grid-area: actions;
  }
}
</style>
