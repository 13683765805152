// Выбрать грамматически верное написание единицы измерения.
export const selectUnit = (amount, units) => {
  if (typeof units === 'string') {
    // Получили строку, выберем единственно возможный вариант.
    return units
  }

  if (!units || !units.length) {
    return ''
  }

  if (units.length >= 3) {
    // Выберем форму множественного числа из трёх предоставленных вариантов:
    // один, два-четыре, много.
    if (amount % 10 === 1 && amount % 100 !== 11) {
      return units[0]
    }
    if (amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)) {
      return units[1]
    }
    return units[2]
  }

  if (units.length >= 2) {
    // Выберем форму множественного числа из двух предоставленных вариантов:
    // один, много.
    if (amount % 10 === 1 && amount % 100 !== 11) {
      return units[0]
    }
    return units[1]
  }

  // Выберем единственно возможный вариант.
  return units[0]
}

export const formatInt = (function () {
  const fmt = new Intl.NumberFormat(undefined, {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: 0
  })

  return (amount, units) => {
    if (isNaN(amount) || amount === undefined || amount === null) {
      return ''
    }
    const unit = selectUnit(amount, units)
    return fmt.format(amount).replace(/\s+/g, '\u202f') + (unit !== '' ? `\u00a0${unit}` : '')
  }
})()

export const formatSize = (function () {
  const units = ['байт', 'Кбайт', 'Мбайт', 'Гбайт']

  return function (size) {
    if (isNaN(size) || size === undefined || size === null) {
      return ''
    }

    let unit = units[0]
    for (let i = 1; i < units.length && size > 999.99; i++) {
      size /= 1024.00
      unit = units[i]
    }

    return formatInt(size, unit)
  }
})()

export const disableMediaDownloads = (() => {
  const _disableMediaDownloads = (node) => {
    if (node instanceof HTMLElement) {
      if (node.localName === 'audio' || node.localName === 'video') {
        node.setAttribute('controlsList', 'nodownload')
        node.addEventListener('contextmenu', (evt) => {
          evt.preventDefault()
        })
      } else {
        node.querySelectorAll('audio, video').forEach(_disableMediaDownloads)
      }
    }
  }

  return () => {
    _disableMediaDownloads(document.body)

    return new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          Array.from(mutation.addedNodes || [])
            .filter(node => node.nodeType === Node.ELEMENT_NODE)
            .forEach(_disableMediaDownloads)
        }
      })
    })
  }
})()

export const showHiddenIframes = (() => {
  const showHiddenIframe = (node) => {
    if (node instanceof HTMLElement) {
      if (node.localName === 'iframe' && node.hidden) {
        node.addEventListener('load', (evt) => {
          (evt.currentTarget || evt.target).hidden = false
        })
      } else {
        node.querySelectorAll('iframe').forEach(showHiddenIframe)
      }
    }
  }

  return () => {
    showHiddenIframe(document.body)

    return new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          Array.from(mutation.addedNodes || [])
            .filter(node => node.nodeType === Node.ELEMENT_NODE)
            .forEach(showHiddenIframe)
        }
      })
    })
  }
})()

// Подготовить к полноценному использованию сущность, полученную по HTTP-API.
export const prepareEntity = function (entity) {
  if (entity && entity instanceof Object) {
    entity.created_at = new Date(entity.created_at)
    entity.updated_at = new Date(entity.updated_at)
    entity.deleted_at = (entity.deleted_at && new Date(entity.deleted_at)) || undefined

    if (entity.title !== undefined) {
      entity.title = (entity.title || '').trim()
    }

    if (entity.description !== undefined) {
      entity.description = (entity.description || '').trim()
    }
  }

  return entity
}

export const getParamFrom = function () {
  const _from = new URLSearchParams(location.search).get('_from')
  return _from ? `?_from=${encodeURIComponent(_from)}` : ''
}

export const getCookie = (name) => {
  let value
  const substring = `${name}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')

  for (const cookie of cookies) {
    if (cookie.includes(substring)) {
      value = cookie.replace(substring, '').trim()
      break
    }
  }

  return value
}

// Полноэкранный режим
export const fullscreen = (element) => {
  if (!element) {
    return
  }

  const requestFullScreen = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullscreen || element.msRequestFullscreen
  const fullscreenEnabled = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullscreenEnabled || document.msFullscreenEnabled

  if (requestFullScreen instanceof Function && fullscreenEnabled) {
    requestFullScreen.call(element)
  }
}

// Выйти из полноэкранного режима.
export const exitFullscreen = () => {
  const exit = document.exitFullscreen || document.webkitExitFullscreen || document.mozExitFullscreen || document.msExitFullscreen
  const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement

  if (exit instanceof Function && fullscreenElement) {
    exit.call(document)
  }
}
