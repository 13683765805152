<template>
  <VCard
    v-if="survey"
    :class="['survey-card', {'survey-card_advanced': advanced}]"
    v-on="events"
  >
    <header>
      <VListItem dense>
        <VListItemContent>
          <VListItemSubtitle
            v-if="!(survey.is_started && (survey.is_active || survey.is_completed))"
            class="text-overline error--text text-wrap"
          >
            {{ $t('$app.survey_unavailable') }}
          </VListItemSubtitle>
          <VListItemSubtitle
            v-else
            :class="['text-overline text-wrap', status.classNames]"
          >
            {{ $t(status.title) }}
          </VListItemSubtitle>
        </VListItemContent>
      </VListItem>
      <VDivider />
    </header>

    <div class="survey-card__body">
      <VListItem>
        <VListItemContent>
          <VListItemTitle class="display-1 text-wrap">
            {{ survey.title }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListItem>
        <VListItemContent>
          <VRow v-if="!advanced">
            <VCol
              cols="12"
              sm="12"
              md="6"
            >
              <p class="survey-card__text--strong">
                {{ $t('courses.one') }}:
              </p>
              {{ survey.course_title }}
            </VCol>

            <VCol
              cols="12"
              sm="12"
              md="6"
            >
              <p class="error--text">
                {{ isAvailableSurvey }}
              </p>

              <VRow>
                <VCol>
                  <span class="survey-card__text--strong">{{ $t('started_at.one') }}:</span>
                  {{ formatDateTime(survey.started_at) || ' ...' }}
                </VCol>

                <VCol>
                  <span class="survey-card__text--strong">{{ $t('completed_at.one') }}:</span>
                  {{ formatDateTime(survey.completed_at) || ' ...' }}
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <template v-else>
            <div class="survey-card__text--strong">
              {{ $t('courses.one') }}:
            </div>
            {{ survey.course_title }}
          </template>
        </VListItemContent>
      </VListItem>

      <VListItem
        v-if="advanced"
        class="error--text"
      >
        {{ isAvailableSurvey }}
      </VListItem>

      <VListItem
        v-if="advanced"
        class="d-block"
      >
        <div>
          <span class="survey-card__text--strong">{{ $t('started_at.one') }}:</span>
          {{ formatDateTime(survey.started_at) || ' ...' }}
        </div>

        <div>
          <span class="survey-card__text--strong">{{ $t('completed_at.one') }}:</span>
          {{ formatDateTime(survey.completed_at) || ' ...' }}
        </div>
      </VListItem>
    </div>

    <VCardActions
      v-if="advanced && survey.is_active && !survey.is_completed"
      tag="footer"
      class="actions"
    >
      <VBtn
        v-if="survey?.status?.code === 'started'"
        color="primary"
        @click.stop="surveyContinue"
      >
        <VIcon left>
          play_arrow
        </VIcon>
        {{ $t('$app.continue_survey') }}
      </VBtn>

      <VBtn
        v-else
        color="accent"
        @click.stop="surveyStart"
      >
        <VIcon left>
          play_arrow
        </VIcon>
        {{ $t('$app.start_survey') }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapActions } from 'vuex'

import { UDate } from '@/utils/date'
import * as actions from '@/store/actions/types'
import { prepareSurveyStatus } from '@/utils/status'

export default {
  name: 'SurveyCard',

  props: {
    survey: {
      type: Object,
      default: null
    },

    advanced: {
      type: Boolean,
      default: false,
    },

    to: {
      type: [Object, String],
      default: ''
    }
  },

  computed: {
    status () {
      const code = this.survey?.status?.code
      return prepareSurveyStatus(code)
    },

    isAvailableSurvey () {
      const surveyStartsAt = new UDate(this.survey?.starts_at).valueOf()
      const surveyEndsAt = new UDate(this.survey?.ends_at).valueOf()
      let str = ''

      if (!surveyStartsAt && !surveyEndsAt) { return '' }

      if (surveyStartsAt && (surveyStartsAt > Date.parse(this.now))) {
        // Обучение будет доступно только…
        str += this.$t('$app.survey_possible_will_be_only')
      } else if (surveyEndsAt && (surveyEndsAt + UDate.dayInMilliseconds < Date.parse(this.now))) {
        // Обучение было доступно только…
        str += this.$t('$app.survey_possible_will_be_only')
      } else {
        // Обучение можно пройти только…
        str += this.$t('$app.survey_possible_only')
      }

      // …С <date> по <date>
      if (surveyStartsAt === surveyEndsAt) {
        str += ` ${UDate.formatDateTime(surveyStartsAt)}`
        return str
      }
      if (surveyStartsAt) {
        str += ` ${this.$t('from.one').toLowerCase()} ${UDate.formatDateTime(surveyStartsAt)}`
      }

      if (surveyEndsAt) {
        str += ` ${this.$t('to.one').toLowerCase()} ${UDate.formatDateTime(surveyEndsAt - UDate.minuteInSeconds)}`
      }

      return str
    },

    events () {
      return this.to ? { click: this.goToSurvey } : {}
    },
  },

  methods: {
    formatDateTime: UDate.formatDateTime,

    ...mapActions({
      startSurvey: actions.START_SURVEY,
      continueSurvey: actions.CONTINUE_SURVEY,
    }),

    async surveyStart () {
      try {
        const response = await this.startSurvey(this.survey.id)
        this.handleResponse(response)
      } catch (e) {
        //
      }
    },

    async surveyContinue () {
      try {
        const response = await this.continueSurvey(this.survey.id)
        this.handleResponse(response)
      } catch (e) {
        //
      }
    },

    handleResponse ({ survey }) {
      if (!survey && survey.id) {
        return
      }

      const path = { name: 'survey', params: { surveyID: survey.id } }

      this.$router.push(path).catch(() => {})
    },

    goToSurvey () {
      this.$router.push(this.to).catch(() => {})
    },
  }
}
</script>

<style lang="scss">
.survey-card {
  height: 100%;
  min-height: unset;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 0.5rem;
}

.survey-card_advanced {
  min-height: 24rem;
}

.survey-card__body {
  flex-grow: 1;
}

.survey-card__avatar {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid currentColor;
  padding: $spacer;
  align-self: flex-start;
}

.survey-card__avatar--started,
.survey-card__avatar--accepted {
  border-width: 0;
  padding: 0;
}

.survey-card__avatar--accepted {
  background: var(--v-success-base);
}

.survey-card__text--strong {
  font-weight: 600;
}
</style>
