<template>
  <div class="task__answers">
    <template v-if="isRate">
      <p>Выберете ответ на&nbsp;шкале:</p>
      <VSlider
        :value="value / 10"
        color="primary"
        :min="1"
        :max="10"
        :step="1"
        :tick-labels="ticksLabels"
        track-color="#D9D9D9"
        :readonly="isReadonly"
        @input="onUpdate($event * 10)"
      />
    </template>

    <template v-else-if="isStars">
      <p>Ваша оценка:</p>
      <VRating
        background-color="warning lighten-1"
        color="warning"
        :value="value / 20 || 1"
        :readonly="isReadonly"
        hover
        @input="onUpdate($event * 20)"
      />
    </template>

    <template v-else-if="isLikes">
      <p>Выберете один вариант ответа:</p>
      <UGradingSmiles
        :readonly="isReadonly"
        :value="Math.round(value / 50)"
        @input="onUpdate(Math.floor($event * 50))"
      />
    </template>
  </div>
</template>

<script>
import UGradingSmiles from '@components/UGradingSmiles.vue'

export default {
  name: 'TrainingQuizQuestionTypeGrading',

  components: { UGradingSmiles },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: 'rate',
    },

    isReadonly: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      ticksLabels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },

  computed: {
    isRate () {
      return this.type === 'rate'
    },

    isStars () {
      return this.type === 'stars'
    },

    isLikes () {
      return this.type === 'likes'
    }
  },

  methods: {
    onUpdate (value) {
      this.$emit('input', [value])
    },

  }
}
</script>
